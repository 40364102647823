<template>
    <div class="itemPop">
        <Popup v-model="isShow" position="bottom"  getContainer="body">
            <div class="popsContent">
                <div class="title">{{(itemList[0] || {type:''}).type == 'takeOut'?'选择供应商':'选择品类'}}</div>
                <div v-if="flag" class="sub-title" @click="openAd">
                    <Svgs color="#fff" classes="iconlaba" name="iconlaba"></Svgs>
                    <span>百万餐厅来袭，查看详情！</span>
                    <Svgs color="#fff" name="iconshouzhi1"></Svgs>
                </div>
                <div v-if="(itemList[0] || {type:''}).type == 'takeOut' && flag2" class="backg_blue" @click="openAd">
                    <Svgs color="var(--themeColor)" classes="iconlaba" name="icon-gonggao"></Svgs>
                    <span>新增小秘书外卖，支持水果茶歇及团餐预订</span>
                </div>
                <div class="itemContent">
                    <div
                        class="item" 
                        v-for="(item, index) in itemList"
                        v-show="item.handle || item.show"
                        :key="index"
                        @click="handlechannel(item)"
                    >   
                        <div class="icon-content icon-content-nonal" >
                            <Svgs :color="colors[event.tenant_code]" :name="item.icon"></Svgs>
                        </div>
                        <div class="subTitle">{{item.txt}}</div>
                    </div>
                </div>
                <div class="cancelBtn" @click="open">取消</div>
            </div>
        </Popup>
    </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import { miceService } from "@/service/miceService.js";
import mealSYT from "@/components/Meal/mealSYT.js";
import { Colors } from '@/utils/colors.js'
import { Popup } from 'vant';
export default {
        components:{Popup},
    props: {
        itemList: {
				type: Array,
				default: () => {
                    return []
                }
			}
    },
    data() {
        return {
            colors: {},
            isShow: false
        }
    },
    mixins: [mealSYT],
    mounted() {
        this.colors = Colors
    },
    computed: {
        ...mapGetters([
            'event',
            'memsg'
        ]),
        flag: {
            get: function() {
                let result = false
                this.itemList.forEach(item => {
                    if(item.handle === "OutMealPlatform") {
                        result = true
                    }
                })
                return result;
            }    
        },
        flag2: {
            get: function() {
                let result = false
                this.itemList.forEach(item => {
                    if(item.itemType === "xiaomishu" && item.show) {
                        result = true
                    }
                })
                return result;
            }    
        }
    },
    methods: {
        ...mapMutations(["ORDER_DETAIL"]),
        open() {
            this.isShow = !this.isShow
            // this.$refs.pop.changePop();
        },
        handlechannel(item) {
            if(item.type == 'takeOut'){
                this.$emit('handlechannel', item.itemType);
                return
            }
            let params = {
                miceId: this.event.proposalId,
                itemType: item.itemType,
            }
            miceService.checkItemTypeLocked(params).then(res => {
                if(res && res.success) {
                    this.addDemand(item.handle)
                }
            })
        },
        addDemand(name) {
            this["ORDER_DETAIL"]({});
            switch (name) {
                case "OutMealPlatform":
                    this.$router.push({
                        path: "/addMealDemand?shopType=平台到餐"
                    });
                    break;
                case "OutMealMT":
                    this.$router.push({
                        path: "/addMealDemand?shopType=美团到餐"
                    });
                    break;
                case "OutMealSYT":
                    this.checkOpenPage();
                    break;
                case "Waimai":
                    // if(['novartis'].includes(this.event.tenant_code)) {
                    //     this.$emit('chooseHos')
                    // } else {
                        this.$router.push({ path: "/addTakeOutDemand" });
                    // }
                    break;
            }
        },
        async checkOpenPage() {
            var formdata = new FormData();
            formdata.append("jobnum", this.memsg.userId);
            formdata.append("name", this.memsg.userName);
            formdata.append("phone", this.memsg.phoneNumber);
            miceService.checkSYTAuthed(formdata).then(res => {
                if (res && res.success) {
                    if (res.code == 200) {
                        this.$router.push({ path: "/addMealDemand?shopType=商宴通" });
                    } else if (res.code == 201) {
                        this.openCheckSYT(res, async () => {
                            miceService.checkSYTAuthed(formdata).then(result => {
                                if(result && result.success) {
                                    if (result.code == 200) {
                                        this.$router.push({ path: "/addMealDemand?shopType=商宴通" });
                                    }
                                }
                            })
                        });
                    }
                }
            })
        },
        openAd() {
            this.$emit("openAd")
        }
        // async checkSYT() {
        //     var formdata = new FormData();
        //     formdata.append("jobnum", this.memsg.userId);
        //     formdata.append("name", this.memsg.userName);
        //     formdata.append("phone", this.memsg.phoneNumber);
        //     miceService.checkSYTAuthed(formdata).then(res => {
        //         if (res && res.success) {
        //             debugger
        //             return res;
        //         }
        //     });
        // }
    }
};
</script>
<style lang="scss">
@import "ItemPop";
.backg_blue {
    text-align: left;
    position: relative;
    height: 30px;
    line-height: 30px;
    background: rgba(56, 117, 254, 0.15);
    width: 90%;
    margin: 0 auto;
    padding-left: .1rem;
    color: var(--themeColor);
    .icon {
        width: 0.18rem !important;
        height: 0.18rem !important;
        position: absolute;
        top: 6px;
    }

    span:last-child{
        margin-left: .23rem;
    }
    span {
        font-size: 12px;
    }
}
</style>
